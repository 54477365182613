

































































































































































































import Vue from "vue";
import { getHistoryAttendanceList } from "@/api/checkingIn/index";
import { reportList } from "@/api/system/dict";

export default Vue.extend({
  components: {},
  data() {
    return {
      searchObj: {
        name: "",
        workNumber: "",
        isZero: "false",
        dateTag: "",
      },
      formatType: "yyyy年M月",
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],
      pickerOptions: {
        disabledDate(time: Date) {
          return time.getTime() > Date.now();
        },
      },
      userList: [],

      //字典数据
      reportList: [],
    };
  },
  watch: {},
  created() {
    this.searchObj.dateTag =
      new Date().getFullYear() + "年" + (new Date().getMonth() + 1) + "月";
  },
  mounted() {
    this.reportList = reportList as any;
    this.getList();
  },
  methods: {
    //common
    resetFn() {
      this.searchObj = this.$options.data().searchObj;
      this.searchFn();
    },
    searchFn() {
      this.pageObj = this.$options.data().pageObj;
      this.getList();
    },
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
    },
    //业务
    getListData() {
      let obj = Object.assign({}, this.searchObj);
      getHistoryAttendanceList(obj).then((res: any) => {
        this.tableData = res.content || [];
        this.tableTotal = res.content?.total || 0;
      });
    },
    getDepartment(depart: any, flag = true) {
      let department_ = "";
      if (flag) {
        department_ = this.showDepartment(depart);
        if (department_.length > 0) {
          department_ = department_.substr(0, department_.length - 1);
        }
      } else {
        department_ = depart?.name || "";
      }

      return department_;
    },
    showDepartment(depart: any) {
      let department_ = "";
      if (depart && Object.keys(depart).length > 0) {
        department_ = depart.name + "/" + department_;
        department_ = this.showDepartment(depart.parent) + department_;
      }

      return department_;
    },
  },
});
